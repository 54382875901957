// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-cake-apps-js": () => import("./../../../src/pages/cake-apps.js" /* webpackChunkName: "component---src-pages-cake-apps-js" */),
  "component---src-pages-cake-js": () => import("./../../../src/pages/cake.js" /* webpackChunkName: "component---src-pages-cake-js" */),
  "component---src-pages-crunchyroll-js": () => import("./../../../src/pages/crunchyroll.js" /* webpackChunkName: "component---src-pages-crunchyroll-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-moviepass-js": () => import("./../../../src/pages/moviepass.js" /* webpackChunkName: "component---src-pages-moviepass-js" */),
  "component---src-pages-sysco-js": () => import("./../../../src/pages/sysco.js" /* webpackChunkName: "component---src-pages-sysco-js" */)
}

